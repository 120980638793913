@import '../../styles/index';

.base {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem;
  background-color: $neutral-0;
  border: 2px solid $surrogate-9;
}

@import '../../styles/index';

.base {
  @include accent();
}

.reset {
  --accent--100: initial;
  --accent-rgb--100: initial;
  --accent--80: initial;
  --accent-rgb--80: initial;
  --accent--60: initial;
  --accent-rgb--60: initial;
  --accent--40: initial;
  --accent-rgb--40: initial;
  --accent--20: initial;
  --accent-rgb--20: initial;
  --accent--10: initial;
  --accent-rgb--10: initial;
  --accent--05: initial;
  --accent-rgb--05: initial;
  --accent--03: initial;
  --accent-rgb--03: initial;
}

.blue,
.green,
.orange,
.red,
.surrogate,
.turquoise,
.violet,
.yellow {
  content: '';
}

@import '../../styles/index';

.base {
  border-radius: 6px;
  font-size: $font-size-16;

  @include media-from(sm) {
    margin-top: auto;
  }
}

@mixin product-overview-button-theme($base-color, $color-10, $shadow) {
  color: $base-color;
  background: $color-10;

  &:focus {
    box-shadow: $shadow;
  }

  &.active {
    color: white;
    background: $base-color;
  }
}

.yellow {
  @include product-overview-button-theme(
    $yellow-9,
    $yellow-3,
    $shadow-product-overview-button-yellow
  );
}

.green {
  @include product-overview-button-theme(
    $green-9,
    $green-3,
    $shadow-product-overview-button-green
  );
}

.turquoise {
  @include product-overview-button-theme(
    $turquoise-9,
    $turquoise-3,
    $shadow-product-overview-button-turquoise
  );
}

.blue {
  @include product-overview-button-theme(
    $blue-9,
    $blue-3,
    $shadow-product-overview-button-blue
  );
}

.orange {
  @include product-overview-button-theme(
    $orange-9,
    $orange-3,
    $shadow-product-overview-button-orange
  );
}

.purple {
  @include product-overview-button-theme(
    $violet-9,
    $violet-3,
    $shadow-product-overview-button-violet
  );
}

.surrogate {
  @include product-overview-button-theme(
    $surrogate-9,
    $surrogate-3,
    $shadow-product-overview-button-violet
  );
}

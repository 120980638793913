@mixin accent() {
  @each $color in map-keys($colors) {
    $palette: map-get($colors, $color);

    &.#{$color + ''} {
      @each $swatch in map-keys($palette) {
        $value: map-get($palette, $swatch);

        --accent--#{$swatch}: #{$value};
        --accent-rgb--#{$swatch}: #{red($value)}, #{green($value)},
          #{blue($value)};
      }
    }
  }
}

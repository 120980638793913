@import '../../styles/index';

.popup {
  position: absolute;
  z-index: 2;
  width: 18rem;
  transform: translate(-50%, 0.125rem);
  left: 50%;
  opacity: 0;
  transition: opacity $duration-default;
  pointer-events: none;

  @include media-from(xsbetween) {
    width: 20rem;
  }

  @include media-from(sm) {
    width: 25rem;
  }
}

.is-visible {
  opacity: 1;
  pointer-events: all;
}

.headline {
  font-weight: $font-weight-medium;
  font-size: $font-size-18;
  line-height: 1.5;
  margin: 0 0 0.25rem;

  @include media-from(sm) {
    font-size: $font-size-20;
  }
}

.description {
  font-weight: $font-weight-light;
  font-size: $font-size-14;
  line-height: 1.5;
  margin: 0;

  @include media-from(sm) {
    font-size: $font-size-16;
  }
}

@import '../../styles/index';

.wrapper {
  background-color: transparent !important;
  padding: 0;
}

.base {
  display: flex;
  flex-direction: column;
  max-width: 100%;

  @include media-from(sm) {
    flex-direction: row;
  }
}

.sides {
  flex-grow: 1;
  padding: 3rem 1.5rem;
  width: 100%;

  @include media-from(xs) {
    padding: 4rem 2rem;
  }

  @include media-from(sm) {
    padding: 4rem 3rem;
  }

  .inner-left {
    margin: 0 auto;

    & > *:not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  .inner-right {
    margin: 0 auto;
    max-width: 40rem;

    span:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }
  }
}

.left {
  background-color: $neutral-0;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  padding: 2.5rem 1.5rem 0.5rem 1.5rem;

  @include media-from(xs) {
    border-bottom-left-radius: $border-radius-default;
    border-top-left-radius: $border-radius-default;
    border-top-right-radius: $border-radius-default;
  }

  @include media-from(sm) {
    border-top-right-radius: 0;
    max-width: 35%;
    min-width: 360px;
    padding: 2rem 1.5rem 3rem 1.5rem;
  }
}

.right {
  background-color: $neutral-0;
  border-bottom-left-radius: $border-radius-default;
  border-bottom-right-radius: $border-radius-default;
  border-top: 1px solid $neutral-7;
  border-top-right-radius: 0;
  padding: 1.5rem 1.25rem 1.875rem 1.25rem;

  @include media-from(sm) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: $border-radius-default;
    border-left: 1px solid $neutral-7;
    border-top: none;
    border-top-right-radius: $border-radius-default;
    padding: 48px 32px 48px 30px;
  }

  .inner-right {
    max-width: 100%;
  }
}

.right-description {
  font-size: 1rem;
  line-height: 1.5;

  strong {
    font-weight: $font-weight-medium;
  }

  ul {
    li {
      padding-left: 2rem;

      &::before {
        height: 1.125rem;
        margin-left: -2rem;
        width: 1.125rem;
      }
    }
  }
}

.downloads {
  &:hover > span,
  &:hover > i {
    transform: translate3d(0, 0, 0) !important;
  }

  > i {
    margin-right: 0.25rem !important;
  }

  > span {
    font-size: 1rem !important;
    line-height: 1.5;
  }
}

.orange {
  .right {
    border-color: transparentize($color: $orange-9, $amount: 0.8);
    @include media-from(sm) {
      border-color: transparentize($color: $orange-9, $amount: 0.8);
    }
  }
}

.turquoise {
  .right {
    border-color: transparentize($color: $turquoise-9, $amount: 0.8);

    @include media-from(sm) {
      border-color: transparentize($color: $turquoise-9, $amount: 0.8);
    }
  }
}

.details-summary {
  align-items: flex-start;
  background-color: var(--accent--10);
  border-radius: 0.5rem;
  color: var(--accent--100);
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  gap: 0.5rem;
  line-height: 1.125rem;
  margin-block: 0.5rem 1rem;
  padding: 0.75rem 1rem 0.875rem;

  button {
    appearance: none;
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.4;
    margin: 0;
    padding: 0;
    position: relative;
    text-decoration: underline;
    transition: color 0.2s ease;
  }
}

.tabs-root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tabs-list {
  border-bottom: 1px solid var(--accent--20, $surrogate-4);
  display: flex;
  flex-shrink: 0;
}

.tabs-trigger {
  align-items: center;
  background: transparent;
  border: none;
  color: $neutral-11;
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: center;
  line-height: 1;
  padding: 1rem;
  position: relative;
  user-select: none;

  span {
    transition:
      color $duration-default,
      transform $duration-default;
  }

  &:not([data-state='active']) {
    &:hover {
      color: var(--accent--100, $surrogate-9);

      span {
        transform: translateY(-2px);
      }
    }
  }

  &:before {
    background-color: var(--accent--80, $surrogate-8);
    bottom: -1px;
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    transform: scale(0);
    transition: transform $duration-default;
    width: 100%;
  }

  &[data-state='active'] {
    color: var(--accent--100, $surrogate-9);

    &:before {
      transform: scale(1);
    }
  }

  &:focus-visible {
    box-shadow: $shadow-button-focus;
    outline: none;
    position: relative;
  }
}

.tabs-content {
  flex-grow: 1;
  padding: 1.5rem 0 0 0;
}
